<style scoped>
    .expand-row{
        margin-bottom: 16px;
    }
</style>
<template>
    <div>
        <Row class="expand-row">
            <Col span="8">
                <span class="expand-key" >网络类型: &nbsp; </span>
                <!-- <span class="expand-value" style="font-size: large;">{{ row.Aggregation }}</span> -->
				<span class="expand-value" style="font-size: large;">{{ row.Aggregation }}</span>
            </Col>
            <Col span="8">
                <span class="expand-key">区域: &nbsp; </span>
                <span class="expand-value" style="font-size: large;">{{ row.Province }}</span>
            </Col>
            <Col span="8">
                <span class="expand-key">运营商: &nbsp; </span>
                <span class="expand-value" style="font-size: large;">{{ row.Isp }}</span>
            </Col>
        </Row>
    </div>
</template>
<script>
    export default {
        props: {
            row: Object
        }
    };
</script>